import { DeviceType, EventType } from '@wix/platform-editor-sdk';
import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { install } from './components/webComponent/editor.controller';
import { IWebComponent } from './components/webComponent/elements/customElement';
import { deleteMetaSiteCacheData, getMetaSiteCacheData } from './utils/editor';
import {
  addComponentToPage,
  filterPage,
  filterWidgets,
  findPagesThatHaveNotBeenInstalledInThePast,
  reportBiEventGfppSettingsClicked,
  saveSettingsToThirdPartyService,
  updateViewMode,
} from './utils/helpers';
import { updateInstanceId } from './utils/update-instance-id';

import { configureWidgetManifest } from './components/webComponent/manifest';

const TOKEN = 'TOKEN';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  editorOptions,
  flowApi
) => {
  const { firstInstall } = editorOptions;
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
    version?: string;
  } = await editorSDK.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId
  );

  // update the instance-id with the current instance-id in order to
  // solve a bug when duplicating a site also duplicates the instance-id
  try {
    await updateInstanceId(editorSDK, appData);
  } catch (error) {
    console.error('error updating instance id for duplicate site flow ', {
      error,
    });
  }

  const metaSiteData = await getMetaSiteCacheData(editorSDK);

  if (metaSiteData) {
    await deleteMetaSiteCacheData(editorSDK);
  }

  const webComponents: IWebComponent[] =
    appData?.components?.filter((comp: any) => comp?.type === 'WEB') ?? [];

  await editorSDK.document.application.reloadManifest();

  if (firstInstall) {
    const webComponentsPages = filterPage(webComponents as IWebComponent[]);
    const pagesToAdd = await findPagesThatHaveNotBeenInstalledInThePast(
      webComponentsPages as IWebComponent[],
      editorSDK
    );
    if (pagesToAdd.length !== 0) {
      await install(editorSDK, appDefinitionId, metaSiteData, flowApi);
    } else {
      const widgets = filterWidgets(webComponents as IWebComponent[]);
      const pageRef = await editorSDK.pages.getCurrent(TOKEN);

      if (widgets.length === 1) {
        await addComponentToPage({
          flowApi,
          componentDefinition: widgets[0],
          editorSDK,
          instanceId: appData.instanceId,
          applicationId: appData.appDefinitionId,
          metaSiteData,
          pageRef,
        });
      } else {
        const metaSiteId = await editorSDK.document.info.getMetaSiteId(TOKEN);
        // open widgets modal
        editorSDK.editor.openModalPanel(TOKEN, {
          url: `https://www.wix.com/_serverless/dynamic-settings-renderer?wix-sdk-version=${
            editorSDK.info.getSdkVersion(TOKEN).scriptSrc
          }&openWidgetsModal=${true}&componentId=not_exists&metaSiteId=${metaSiteId}`,
          shouldHideHeader: true,
          width: 680,
          height: 423,
        });
      }
    }
  }

  editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    const { detail } = event;

    if (detail.id === 'connect') {
      reportBiEventGfppSettingsClicked(
        flowApi,
        editorSDK,
        event,
        webComponents
      );
    }
  });

  editorSDK.addEventListener(EventType.widgetGfppClicked, async (event) => {
    console.debug('DEBUG WIDGET GFPP CLICKED', event);
    const { detail } = event;

    if (detail.id === 'connect') {
      reportBiEventGfppSettingsClicked(
        flowApi,
        editorSDK,
        event,
        webComponents
      );
    }
  });
  editorSDK.addEventListener(EventType.switchedToMobileView, async () => {
    console.debug('DEBUG SWITCH TO MOBILE');

    const componentsByType = await editorSDK.document.components.findAllByType(
      'token',
      {
        componentType: 'wixui.CustomElementComponent',
      }
    );

    for (const compRef of componentsByType) {
      updateViewMode({
        editorSDK,
        mode: DeviceType.Mobile,
        compRef,
        appDefinitionId,
      });
    }
  });

  editorSDK.addEventListener(EventType.switchedToDesktopView, async () => {
    console.debug('DEBUG SWITCH TO DESKTOP');

    const componentsByType = await editorSDK.document.components.findAllByType(
      'token',
      {
        componentType: 'wixui.CustomElementComponent',
      }
    );

    for (const compRef of componentsByType) {
      updateViewMode({
        editorSDK,
        mode: DeviceType.Desktop,
        compRef,
        appDefinitionId,
      });
    }
  });

  editorSDK.addEventListener(EventType.siteWasPublished, async () => {
    console.debug('DEBUG SITE PUBLISHED');

    for (const component of webComponents) {
      component.data?.gfppSettings?.fetchInitialData &&
        saveSettingsToThirdPartyService({
          appId: appDefinitionId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            componentId: component.componentId,
            instanceId: appData.instanceId,
            status: 'published',
          },
        });
    }
  });

  editorSDK.addEventListener(EventType.themeChanged, async (e) => {
    console.debug('DEBUG THEME CHANGED', e);

    //@ts-ignore
    if (e?.detail?.changeType === 'STYLE') return;

    const [colors, fonts] = await Promise.all([
      editorSDK.document.theme.colors.getAll(TOKEN),
      editorSDK.document.theme.fonts.getMap(TOKEN),
    ]);

    for (const component of webComponents) {
      console.debug(
        'DEBUG THEME CHANGED INSIDE FOR',
        component.data?.gfppSettings?.fetchInitialData
      );

      component.data?.gfppSettings?.fetchInitialData &&
        saveSettingsToThirdPartyService({
          appId: appDefinitionId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            data: {},
            instanceId: appData.instanceId,
            theme: {
              colors,
              fonts,
            },
          },
        });
    }

    const componentsByType = await editorSDK.document.components.findAllByType(
      'token',
      {
        componentType: 'wixui.CustomElementComponent',
      }
    );

    for await (const compRef of componentsByType) {
      updateViewMode({
        editorSDK,
        compRef,
        appDefinitionId,
        reset: true,
      });
    }
  });

  editorSDK.addEventListener(
    EventType.anyComponentAddedToStage,
    async (data) => {
      console.debug('DEBUG ANY COMP');

      const fullData: any = await editorSDK.components.data.get(TOKEN, {
        componentRef: data.detail.compRef,
      });
      // controllerType is the component Id
      const { applicationId, controllerType } = fullData;

      const appDataToUpdate: any =
        await editorSDK.document.tpa.app.getDataByAppDefId(
          applicationId,
          applicationId
        );
      const component: any = appDataToUpdate.components?.find(
        (comp: any) => comp.componentId === controllerType
      );

      if (component.data?.gfppSettings?.fetchInitialData) {
        console.debug('DEBUG ANY COMP INSIDE IF');

        const [colors, fonts] = await Promise.all([
          editorSDK.document.theme.colors.getAll(TOKEN),
          editorSDK.document.theme.fonts.getMap(TOKEN),
        ]);

        saveSettingsToThirdPartyService({
          appId: applicationId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            instanceId: appData.instanceId,
            theme: {
              colors,
              fonts,
            },
          },
        });
      }
    }
  );
};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  { initialAppData }
) => {
  const { appDefinitionId } = initialAppData;

  //widget gfpp settings
  await configureWidgetManifest(appManifestBuilder, editorSDK, appDefinitionId);

  // configure page options
  return appManifestBuilder
    .configurePages((pagesBuilder) => {
      // page settings
      pagesBuilder
        .addSettingsTab(
          {
            title: 'Page Info',
            helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
            type: 'page_info',
          },
          {
            title: 'Layout',
            type: 'layout',
          },
          {
            title: 'Permissions',
            type: 'permissions',
          },
          {
            title: 'SEO',
            helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
            type: 'seo',
          }
        )
        // page actions
        .addAction(
          {
            type: 'page_rename',
          },
          {
            type: 'page_delete',
          }
        );
    })
    .build();
};
